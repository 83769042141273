<template>
  <div>
    <b-button
      id="mortgageOfferAcceptanceSelectButton"
      type="is-text"
      expanded
      class="has-text-weight-medium mt-4"
      @click="openModal"
      >{{ $t("af:offer_details.modal.acceptance.button.select") }}</b-button
    >
  </div>
</template>

<script>
import EmailConfirmationModal from "../EmailConfirmationModal";
import MortgageOfferAcceptanceModal from "./MortgageOfferAcceptanceModal";

export default {
  name: "MortgageOfferAcceptanceModalWrapper",
  components: {},
  props: {
    offerId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async openModal() {
      let isEmailConfirmedResponse = await this.$store.dispatch(
        "profile/isEmailConfirmed"
      );
      let isEmailConfirmed = isEmailConfirmedResponse.isEmailConfirmed;
      let currentEmail = isEmailConfirmedResponse.currentEmail;

      if (isEmailConfirmed) {
        this.$buefy.modal.open({
          parent: this,
          component: MortgageOfferAcceptanceModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            offerId: this.offerId,
          },
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: EmailConfirmationModal,
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
          props: {
            currentEmail: currentEmail,
            offerId: this.offerId,
            mortgage: true,
          },
        });

        await this.$store.dispatch("profile/updateCustomerEmailConfirm", {
          offerId: this.offerId,
        });
      }
    },
  },
};
</script>
